import { AnimationOnScroll } from "./AnimationOnScroll";
const AboutUs = () => {

    return (
        <section className="section-padding" id='about'>
            <div className="container">
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                    <div className='d-flex align-items-baseline'>
                        <div className='line-grid'>
                            <img src="/images/leftgrid.svg" alt="" />
                        </div>
                        <div className="title">
                            <h2>About&nbsp;Coin7</h2>
                        </div>
                        <div className='line-grid'>
                            <img src="/images/rightgrid.svg" alt="" />
                        </div>
                    </div>
                </AnimationOnScroll>
                <div className='d-flex align-items-center mt-5'>
                    <div className="heythere">
                        <img src="/images/5.png" alt="" />
                    </div>
                    <div className="aboutus">
                        <div>
                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                                <p>Coin7 is a project, that aims to revolutionize the 
                                    &nbsp;<span style={{ color: '#7A04EB' }}>
                                        trade of goods and services
                                    </span>
                                    <br />
                                    by building the world’s first marketplace based on cryptocurrencies.
                                </p>
                            </AnimationOnScroll>

                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                                <p>The project provides a secure, fast, and cost-effective alternative to traditional payment methods.
                                    <br />
                                    With its &nbsp; <span style={{ color: '#7A04EB' }}>
                                        MLM and advertising cashback module
                                    </span>&nbsp; , users can generate extra income and actively shape the platform. <br />
                                    The mining module allows users to mine coins directly from their mobile device, contributing to their availability in the market and earning coins.
                                </p>
                            </AnimationOnScroll>

                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                                <p>The advertising module
                                &nbsp;<span style={{ color: '#7A04EB' }}>
                                        advertising module
                                    </span>&nbsp;
                                    offers app users a default 50% revenue share of all advertising income, <br /> and the MLM module allows users to create additional income streams and build a distribution structure.
                                </p>
                            </AnimationOnScroll>

                            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                                <p>All earnings are transferred to the wallet module in the form of coins, which will have a sealed paper wallet and payment gateway functions.<br /> Coin7 is developing its own blockchain technology with the goal of optimizing both speed and security, while lowering transaction fees, resulting in a more satisfying experience for the users. <br />The blockchain will be incorporated into the wallet module, allowing users to conduct transactions quickly and without concern about fees or delays.
                                </p>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default AboutUs