/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import Faq from "../components/faq";
import { useParams } from "react-router-dom";
import './styles.css';
import AboutUs from "../components/aboutus";
import HowWork from "../components/howwork";

const Home = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let id = urlParams.get('referrer')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <section className="main-section bottom-padding" id="download">
        <div className="container">
          <div className="row align-items-center gy-5 mt-3">
            <div className="top-back">
              <img src="/images/topback.svg" alt="" />
            </div>

            <div className="col-lg-12" style={{ zIndex: '1' }}>
              <div className="row align-items-center py-5">
                <div className="col-lg-7">
                  <div className="title pl-10" >
                    <h1>Revolutionizing the <br /><span style={{ color: '#7A04EB' }}>Trade of Goods and </span> <br />Services</h1>
                  </div>
                  <div className="d-flex justify-content-start pl-10">
                    <a href={`http://coin7org.app.link/S435b9ajhAb?referrer=${id}`} target="_blank" rel="noreferrer">
                      <img src="/images/1.png" alt="" className="download-btn" />
                    </a>
                    <a href={`http://coin7org.app.link/S435b9ajhAb?referrer=${id}`} target="_blank" rel="noreferrer">
                      <img src="/images/2.png" alt="" className="download-btn" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="text-center main-gif" style={{ position: 'relative' }}>
                    <img src="/images/phone.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Roadmap /> */}
      <AboutUs />
      <HowWork />
      <Faq />
      <Footer />
    </>
  )
}

export default Home;