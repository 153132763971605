import Home from './containers/home';
import 'react-notifications/lib/notifications.css'
import { NotificationContainer } from 'react-notifications'
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="main">
      <NotificationContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home />} />
          <Route exact path='/:id' element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
