import { AnimationOnScroll } from "./AnimationOnScroll";

const Faq = () => {

    return (
        <section className="section-padding pt-0 overflow-hidden" id='faq'>
            <div className="container ">
                <div className="bottom-back">
                    <img src="/images/topback.svg" alt="" />
                </div>

                <AnimationOnScroll animateIn="animate__fadeInUp" className={"faq-container"} animateOnce={true}>
                    <div className="faq-border-wrap">
                        <div className="faq">
                            <div className='d-flex'>
                                <div className='faq-number'>
                                    <h1 style={{ color: '#9D96A8' }}>01</h1>
                                </div>
                                <div className='faq-content'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 style={{ color: '#7A04EB' }}>Blockchain Database <br /></h3>
                                        <div className='faq-close'>
                                            <img src="/images/faqexit.svg" alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        We are committed to providing our users with the best possible experience when it comes to reliable transactions. In pursuit of this goal, our own blockchain technology will be integrated with our wallet module.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>

                <AnimationOnScroll animateIn="animate__fadeInUp" className={"faq-container"} animateOnce={true}>
                    <div className="faq-border-wrap">
                        <div className="faq">
                            <div className='d-flex'>
                                <div className='faq-number'>
                                    <h1 style={{ color: '#9D96A8' }}>02</h1>
                                </div>
                                <div className='faq-content'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 style={{ color: '#7A04EB' }}>Wallet module <br /></h3>
                                        <div className='faq-close'>
                                            <img src="/images/faqexit.svg" alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        To provide users with a convenient way to keep track of their cryptocurrency, we have created the wallet module as an essential feature of our project. Our wallet module offers several key features that make it stand out from other wallets on the market.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>

                <AnimationOnScroll animateIn="animate__fadeInUp" className={"faq-container"} animateOnce={true}>
                    <div className="faq-border-wrap">
                        <div className="faq">
                            <div className='d-flex'>
                                <div className='faq-number'>
                                    <h1 style={{ color: '#9D96A8' }}>03</h1>
                                </div>
                                <div className='faq-content'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 style={{ color: '#7A04EB' }}>Mining module<br /></h3>
                                        <div className='faq-close'>
                                            <img src="/images/faqexit.svg" alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        By using our unique mining module, app users can mine our coins directly from their mobile devices. With no need for special hardware or technical knowledge, our user-friendly interface makes it easy to start mining with just one click, anytime and anywhere.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>

                <AnimationOnScroll animateIn="animate__fadeInUp" className={"faq-container"} animateOnce={true}>
                    <div className="faq-border-wrap">
                        <div className="faq">
                            <div className='d-flex'>
                                <div className='faq-number'>
                                    <h1 style={{ color: '#9D96A8' }}>04</h1>
                                </div>
                                <div className='faq-content'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 style={{ color: '#7A04EB' }}>Advertising module? <br /></h3>
                                        <div className='faq-close'>
                                            <img src="/images/faqexit.svg" alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        The advertising module offers our app users a default 50% revenue share of all advertising income generated through our app. By using this module, our users can passively participate in our company’s revenue and benefit from it.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInUp" className={"faq-container"} animateOnce={true}>
                    <div className="faq-border-wrap">
                        <div className="faq">
                            <div className='d-flex'>
                                <div className='faq-number'>
                                    <h1 style={{ color: '#9D96A8' }}>05</h1>
                                </div>
                                <div className='faq-content'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 style={{ color: '#7A04EB' }}>MLM module <br /></h3>
                                        <div className='faq-close'>
                                            <img src="/images/faqexit.svg" alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        Accelerated growth through our multi-level marketing module <br />
                                        By using the MLM module, app users can generate additional income streams by building their networks and recommending the app to friends and colleagues.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInUp" className={"faq-container"} animateOnce={true}>
                    <div className="faq-border-wrap">
                        <div className="faq">
                            <div className='d-flex'>
                                <div className='faq-number'>
                                    <h1 style={{ color: '#9D96A8' }}>06</h1>
                                </div>
                                <div className='faq-content'>
                                    <div className='d-flex justify-content-between'>
                                        <h3 style={{ color: '#7A04EB' }}>Marketplace for Goods and Services <br /></h3>
                                        <div className='faq-close'>
                                            <img src="/images/faqexit.svg" alt="" />
                                        </div>
                                    </div>
                                    <p>
                                        Our team has been working tirelessly to create the world’s first cryptocurrency-based marketplace for goods and services. We believe that this innovative platform will revolutionize the way people transact and pave the way for a new era of decentralized commerce.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
            </div>
        </section>
    );
}

export default Faq