/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext} from "react";

export const AppContext = createContext();

export const AppcontextProvider = ({ children }) => {

  return (
    <AppContext.Provider
      value={{}}
    >
      {children}
    </AppContext.Provider>
  );
};
