/* eslint-disable jsx-a11y/alt-text */
import { AnimationOnScroll } from "./AnimationOnScroll";

const HowWork = () => {

    return (
        <section className="section-padding" id='how' style={{ padding: 0 }}>
            <div className="container py-5" style={{display: 'grid'}}>
                <div className="middle-back">
                    <img src="/images/topback.svg" alt="" />
                </div>
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                    <div className="title">
                        <h2>Coin7 makes crypto mining easy.</h2>
                    </div>
                </AnimationOnScroll>
                <div className="row howwork">
                    <div className="col-lg-4 col-md-6 pt-5 how-padding" >
                        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true} style={{height: '100%'}}>
                            <div className="module-border-wrap" style={{height: '100%'}}>
                                <div className="module how1" style={{height: '100%'}}>
                                    <h1>01</h1>
                                    <h3>Decentralized:</h3>
                                    <p>
                                        Secure, Immutable, non- <br />
                                        conunterfeitable and interoperable <br />
                                        digital money <br />
                                    </p>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-5 how-padding"  >
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} style={{height: '100%'}}>
                            <div className="module-border-wrap" style={{height: '100%'}}>
                                <div className="module how2" style={{height: '100%'}}>
                                    <h1>02</h1>
                                    <h3>Mobile First</h3>
                                    <p>
                                        Works on your mobile phone and <br />
                                        does not drain your battery <br />
                                    </p>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-5 how-padding" >
                        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true} style={{height: '100%'}}>
                            <div className="module-border-wrap" style={{height: '100%'}}>
                                <div className="module how3" style={{height: '100%'}}>
                                    <h1>03</h1>
                                    <h3>User & Planet-Friendly</h3>
                                    <p>
                                        Easy to use, secure at scale <br />
                                        without the massive electrical<br />
                                        waste. <br />
                                    </p>
                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowWork
